$white: #fff;
$lightgray: #e1e1e1;
$dimmedgray: #666;
$gray: #3a3a3a;
$lightblack: #191919;
$black: #000;

$blue900: #010066;
$blue800: #000199;
$blue700: #0001cd;
$blue600: #0100e4;
$blue500: #021bf5;
$blue400: #3132ff;
$blue300: #4140fe;
$blue200: #5455ff;
$blue100: #8889ff;
$blue50: #cecfff;

$orange900: #ba4301;
$orange800: #d14a00;
$orange700: #e65100;
$orange600: #ff4c01;
$orange500: #f47d00;
$orange400: #fd9800;
$orange300: #feb74b;
$orange200: #fdcc7f;
$orange100: #ffe0b1;
$orange50: #fef3df;
