$orange500: #ffb100;
$orange200: #fbc252;
$orange: $orange500;
$beige: #f0eccf;
$sage: #a3bb98;
$blue: #021bf5;
$lightblue: #8889ff;
$black: #343a40;
$gray: #adb5bd;
$darkgray: darken($gray, 20%);
$lightgray: #dee2e6;
$white: #fcfcfc;

.wavesurfer {
  margin: 0.5rem 0;
  position: relative;
  & * {
    box-sizing: border-box;
  }
  & &-wrapper {
    margin: 0.5rem 0;
    position: relative;
    padding: 1rem 1rem 2.5rem 3.4rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
  }
  & &-play {
    position: absolute;
    z-index: 3;
  }
  & &-play {
    top: calc(1rem - 1px);
    left: 0.5rem;
    width: 42px;
    height: 42px;
    border-radius: 50%;
    background-color: lighten($orange, 42%);
    &:hover {
      background-color: lighten($orange, 35%);
    }
    & svg {
      fill: $orange;
    }
  }
  &-controls {
    position: absolute;
    left: 0.5rem;
    right: 1rem;
    bottom: 0;
  }
  & &-download {
    padding: 0.1rem 0.5rem;
    border-radius: 1rem;
    font-size: 11px;
    text-transform: none;
    background: $darkgray;
    box-shadow: none;
    &:hover {
      background: darken($darkgray, 10%);
      box-shadow: none;
    }
    // padding: 0.2rem;
    // width: 1.8rem;
    // height: 1.8rem;
    // border-radius: 50%;
    // border: 1px solid $darkgray;
    svg {
      width: 1rem;
      height: 1rem;
      fill: #fff;
    }
  }
  & &-volume {
    & button {
      box-shadow: none;
    }
    & svg {
      fill: $darkgray;
    }
    & .MuiSlider-thumb {
      background: $darkgray;
      box-shadow: none;
      &::before {
        display: none;
      }
    }
    & .MuiSlider-track {
      background: $darkgray;
      border: none;
    }
    & .MuiSlider-rail {
      background: $lightgray;
      opacity: 1;
    }
    & .MuiSlider-valueLabel {
      &::before {
        display: none;
      }
    }
  }
  &-waveform {
    width: 100%;
    wave {
      overflow: hidden !important;
    }
  }
  & &-time {
    span {
      background: $lightgray;
      padding: 0.1rem 0 0;
      display: block;
      width: 70px;
      text-align: center;
      border-radius: 1rem;
      font-size: 11px;
      color: darken($gray, 20%);
      font-family: 'Roboto', sans-serif;
      // &:first-child {
      //   left: 0.5rem;
      // }
      // &:last-child {
      //   right: 0.5rem;
      // }
    }
  }
}
