.st-area-grid {
  .st-area {
    > div {
      position: relative;
      width: 100%;
      height: 100%;
      margin: 0;
      padding: 0;
    }

    .st-panel {
      width: 100%;
      height: 100%;
      margin: 0;
      border-radius: 0;

      header {
        border-radius: 0;
      }
    }
  }
}

// Button
.st-button {
  border-radius: 4px;
  padding: 3px 14px;
  box-shadow: none;
  border: none;

  &.primary {
    background-color: #264790;
    color: #c2c2c2;

    &:hover {
      background-color: #3f5ea3;
    }
  }
}

.tab-controller {
  background-color: #303030;
  margin: 1rem;
  border-radius: 3px;

  .tabs {
    background-color: #000;

    .tab {
      display: inline-block;
      color: #909090;
      padding: 8px 15px;
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
      cursor: pointer;

      &.active {
        color: #fff;
        background-color: #303030;
      }
    }
  }

  .pane {
    padding: 1rem;
  }
}

.st-panel {
  background-color: #202020;
  margin: 1rem;
  border-radius: 4px;
  display: flex;
  flex-direction: column;

  header {
    position: relative;
    padding: 0.2rem 1rem;
    color: #909090;
    line-height: 1.7rem;
    background-color: #303030;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;

    .st-panel-title {
      margin: 0;
    }
  }

  .st-panel-body {
    padding: 1rem 2rem 1rem 1rem;
    overflow: auto;
    height: 100%;
  }
}

.st-floater {
  background-color: gray;

  .st-floater-header {
    height: 10px;
    cursor: move;
  }

  .st-floater-body {
    padding: 3px 5px;
    box-sizing: border-box;
  }
}
