@import './colors';

$blue: $blue500;

$selectionColor: $blue100;
$edgeColor: $orange200;
$highlightColor: $orange200;

html,
body {
  margin: 0;
  padding: 0;
  font-size: 17px;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  overscroll-behavior-x: none;
  overscroll-behavior-y: none;
}

.area-grid {
  display: grid;
  position: relative;
  grid-gap: 0;
  height: 100%;
  min-height: 0; // Chrome bug workaround - https://stackoverflow.com/questions/52645879/css-grid-inside-flexbox-not-working-as-expected-only-on-chrome

  .area {
    position: relative;

    > * {
      width: 100%;
      height: 100%;
      box-sizing: border-box;
    }
  }

  .splitter {
    position: absolute;
    z-index: 1;
    user-select: none;

    &.horizontal {
      width: 100%;
      height: 10px;
      cursor: row-resize;

      &.moving {
        cursor: row-resize;
      }
    }

    &.vertical {
      width: 10px;
      height: 100%;
      cursor: col-resize;

      &.moving {
        cursor: col-resize;
      }
    }

    &.top {
      top: 0;
      left: 0;
    }

    &.right {
      top: 0;
      right: 0;
    }

    &.bottom {
      bottom: 0;
      left: 0;
    }

    &.left {
      top: 0;
      left: 0;
    }
  }
}

.separator {
  background-color: #d2d2d2;
  height: 1px;
  margin-bottom: 2rem;

  & + * {
    margin-top: 2rem;
  }
}

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 23px;
  margin-right: 0.5rem;

  input {
    appearance: none;
    -webkit-appearance: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    margin: 0;
    z-index: 1;

    &:checked + .slider {
      background-color: $blue;
    }

    &:checked + .slider:before {
      transform: translateX(17px);
    }
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.2s;
    border-radius: 11px;

    &:before {
      position: absolute;
      content: '';
      height: 17px;
      width: 17px;
      left: 3px;
      bottom: 3px;
      background-color: $white;
      transition: 0.2s;
      border-radius: 50%;
    }
  }
}

button {
  appearance: none;
  -webkit-appearance: none;
  background-color: #fafbfc;
  border: 1px solid rgba(27, 31, 35, 0.15);
  border-radius: 6px;
  box-shadow: rgba(27, 31, 35, 0.04) 0 1px 0,
    rgba(255, 255, 255, 0.25) 0 1px 0 inset;
  box-sizing: border-box;
  color: #24292e;
  cursor: pointer;
  display: inline-block;
  font-size: 0.9rem;
  font-weight: 500;
  line-height: 20px;
  list-style: none;
  padding: 6px 16px;
  position: relative;
  transition: background-color 0.2s cubic-bezier(0.3, 0, 0.5, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  white-space: nowrap;
  word-wrap: break-word;

  &:hover {
    background-color: #f3f4f6;
    text-decoration: none;
    transition-duration: 0.1s;
  }

  &:disabled {
    background-color: #fafbfc;
    border-color: rgba(27, 31, 35, 0.15);
    color: #959da5;
    cursor: default;
  }

  &:active {
    background-color: #edeff2;
    box-shadow: rgba(225, 228, 232, 0.2) 0 1px 0 inset;
    transition: none 0s;
  }

  &:before {
    display: none;
  }

  &:-webkit-details-marker {
    display: none;
  }

  &.small {
    font-size: 0.8rem;
    padding: 0.2rem 0.6rem;
  }

  &.large {
    font-size: 1rem;
    padding: 1rem 1.5rem;
  }

  &.wide {
    width: 100%;
  }

  & + button {
    margin-left: 0.15rem;
  }
}

header {
  color: $white;
  background-color: $lightblack;
  display: flex;
  align-items: center;
  line-height: 150%;
  justify-content: space-between;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 1;

  a {
    display: block;
    color: $white;
    text-decoration: none;
    padding: 0 0.8rem;
    line-height: 32px;
    border-radius: 4px;
    white-space: nowrap;

    &:hover {
      background-color: $black;
    }
  }

  .logo {
    padding-top: 3px;
    box-sizing: border-box;
    margin: 0;
    font-size: 1.5rem;
    font-weight: bold;
  }

  nav {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;

    ul {
      display: flex;
      flex-flow: row wrap;
      margin: 0;

      > li {
        list-style: none;
        box-sizing: border-box;
        font-size: 1rem;

        a {
          &.active {
            background-color: $blue;
            position: relative;
          }
        }
      }
    }
  }

  .user {
    margin-left: 1rem;
    margin-right: 1rem;
    text-align: right;
    flex-grow: 1;

    .name {
      font-size: 0.9rem;
      margin-right: 0.3rem;
    }

    .email {
      font-size: 0.9rem;
      line-height: 1;
    }
  }
}

.area {
  overflow: hidden;
}

.main {
  background-color: $lightgray;
  box-shadow: 2px 0 7px 1px rgba(0, 0, 0, 0.2);
  overflow: auto;

  .sources {
    margin-top: 2rem;
  }

  section {
    margin-bottom: 2rem;

    .inner {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      min-height: 1.4rem;

      > div {
        flex: 10;
      }

      .title {
        font-size: 0.9rem;
        text-transform: uppercase;
        color: $gray;
        font-weight: 800;
      }

      .subtitle {
        font-size: 0.7rem;
      }

      .language-label {
        font-size: 0.9rem;
        color: rgb(58, 58, 58);
        font-weight: 600;
      }
    }

    > .field-name {
      font-size: 0.9rem;
      color: $gray;
      font-weight: 600;
    }
  }

  button {
    &.is-recording {
      color: $orange500;
    }
  }

  .dropzone {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5px;
    transition: background-color 0.1s ease-in-out;
    border-radius: 5px;
    font-size: 0.9rem;
    margin-top: 0.5rem;
    cursor: pointer;

    > p {
      text-align: center;
      display: flex;
      align-items: center;
      > svg {
        margin-right: 0.2rem;
      }
    }
  }
}

.dropzone {
  color: $gray;
  background-color: $white;
  border-style: dashed;
  border-color: $blue100;
  border-radius: 5px;
  &:hover {
    background-color: #f3f4f6;
  }
}

.contents {
  padding: 0rem 2rem;
  overflow-y: auto;

  .description {
    margin-top: 2rem;
    margin-bottom: 2rem;
    font-size: 1rem;
    line-height: 1.2;
    color: #666;

    h1,
    h2 {
      padding-left: 1rem;
      margin-top: 1rem;
      margin-bottom: 1rem;
      font-size: 1.5rem;
      position: relative;
      color: #111;
      font-weight: 600;

      &::before {
        content: '';
        position: absolute;
        display: block;
        left: -0.2rem;
        bottom: 0;
        width: 6px;
        height: 100%;
        border-radius: 4px;
        background: $blue;
      }
    }
    p {
      font-size: 0.9rem;
    }
  }
  .cvc-select {
    div {
      padding: 0.75rem;
      font-size: 1rem;
    }
  }
  .output {
    display: flex;
    flex-flow: column;
    align-items: center;
  }
}

.results {
  .execute {
    button {
      border: none;
      box-shadow: 1px 1px 10px 0 rgb(0 0 0 / 20%);
    }
  }

  .execute.processing {
    display: flex;
    justify-content: space-between;
    button:first-child {
      margin-right: 20px;
    }
  }

  .results-container {
    margin-top: 2rem;

    .title {
      font-size: 0.9rem;
      text-transform: uppercase;
      color: $gray;
      font-weight: 800;
    }

    .result-container {
      display: flex;
      flex-wrap: wrap;
      background-color: $white;
      padding: 1rem;
      border-radius: 6px;
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
      box-shadow: 1px 1px 10px 0 rgb(0 0 0 / 20%);
      align-items: center;

      span {
        font-size: 0.9rem;
        flex: 10;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      audio {
        width: 100%;
        margin-top: 0.4rem;
      }
    }

    .processing {
      font-size: 1.1rem;
      font-weight: 400;
      margin-left: 1rem;
      color: $gray;
      visibility: visible;
    }
  }
}

input[type='checkbox'] {
  margin-left: 0;
  margin-right: 0.3rem;
  vertical-align: middle;
}

.audio-container,
.result-container {
  display: flex;
  flex-wrap: wrap;
  background-color: $white;
  padding: 1rem;
  border-radius: 6px;
  margin-top: 0.3rem;
  margin-bottom: 0.3rem;
  box-shadow: 1px 1px 10px 0 rgb(0 0 0 / 20%);
  align-items: center;

  span {
    font-size: 0.9rem;
    flex: 10;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .btn-delete {
    border: 0;
    cursor: pointer;
    background-color: transparent;
    margin-left: 2px;
    padding: 3px;
    margin-top: 0;

    img {
      width: 10px;
    }
  }

  audio {
    width: 100%;
    margin-top: 0.4rem;
  }

  & + .dropzone {
    margin-top: 0.6rem;
  }

  button {
    margin-top: 0.7rem;
  }
}

.parameter {
  margin-top: 0.2rem;
  margin-bottom: 1.5rem;
  width: 100%;

  &.select {
    label {
      flex: 1;
    }

    select {
      flex: 2;
      appearance: none;
      -webkit-appearance: none;
      background-image: linear-gradient(45deg, transparent 50%, gray 50%),
        linear-gradient(135deg, gray 50%, transparent 50%),
        linear-gradient(to right, $lightgray, $lightgray);
      background-position: calc(100% - 1rem) calc(0.8rem + 2px),
        calc(100% - 1rem + 5px) calc(0.8rem + 2px), calc(100% - 2rem) 0.2rem;
      background-size: 5px 5px, 5px 5px, 1px 1.5rem;
      background-repeat: no-repeat;
      border-radius: 4px;
    }
  }

  &.collapsed {
    margin-bottom: 1.5rem;
  }

  .inner {
    display: flex;
    justify-content: space-between;
    align-items: center;

    label {
      display: inline-block;
      font-size: 0.9rem;
      color: $gray;
      font-weight: 600;
      flex-grow: 1;
      white-space: nowrap;
    }

    .value {
      display: inline-block;
      font-size: 0.9rem;
      color: $blue;
      font-weight: 600;
      width: 2rem;
      text-align: right;
      &.disabled {
        color: $dimmedgray;
      }
    }

    select {
      width: 15rem;
      height: 2rem;
      font-size: 0.9rem;
      padding-left: 0.6rem;
      padding-right: 0.6rem;
      border: 1px solid $gray;
    }

    .duration-input {
      margin-right: 0;
    }
  }

  .slider-container {
    padding-left: 0.2rem;
    padding-right: 0.2rem;

    .rc-slider {
      margin-top: 1rem;
    }
    .MuiSlider-root {
      .MuiSlider-markLabel[data-index='0'] {
        margin-left: 1.6rem;
      }
      .MuiSlider-markLabel[data-index='1'] {
        margin-left: -1.6rem;
      }
    }
  }
}

#area-header {
  overflow: inherit;
}

#area-main {
  padding-right: 10px;
}

#area-results {
  padding-left: 10px;
}

.vie-container {
  background-color: $black;

  .panel {
    position: absolute;
    background-color: #474747;
    border-radius: 10px;
    color: $white;
    padding: 20px;
    opacity: 0.8;

    &.top {
      top: 8px;
    }
    &.left {
      left: 8px;
    }
    &.bottom {
      bottom: 8px;
    }
    &.right {
      right: 8px;
    }

    &.no-bg {
      background-color: inherit;
    }

    .panel-title {
      font-size: 1.5rem;
      display: block;
      margin-bottom: 1rem;
      font-weight: 600;
    }

    dt {
      float: left;
      clear: left;
      width: 7rem;
      font-weight: bold;

      &::after {
        content: ':';
      }
    }

    dd {
      margin: 0 0 0 7rem;
      padding: 0 0 0.5em 0;
    }
  }
}

button {
  &.link {
    color: inherit;
    background-color: inherit;
    border: none;
    box-shadow: none;
    transition: none;
  }

  &.signin {
    height: 46px;
    min-width: 180px;
  }
}

.vd-container {
  .parameters {
    position: absolute;
    top: 1rem;
    left: 3rem;
    right: 3rem;
    bottom: 1rem;
    display: flex;

    .parameter {
      display: inline-block;

      &.gender {
        top: 1rem;
        left: 3rem;
      }

      &.age {
        top: 1rem;
        left: 6rem;
      }

      .title {
        font-size: 1.5rem;
        line-height: 3rem;
      }

      .color {
        display: inline-block;
        width: 5rem;
        height: 5rem;
        border-radius: 20px;
        transform: skew(348deg, 12deg);
        margin-right: 7px;

        &.color1 {
          background-color: #ff6961;
        }

        &.color2 {
          background-color: #ffb480;
        }

        &.color3 {
          background-color: #f8f38d;
        }

        &.color4 {
          background-color: #42d6a4;
        }

        &.color5 {
          background-color: #08cad1;
        }

        &.color6 {
          background-color: #59adf6;
        }

        &.color7 {
          background-color: #9d94ff;
        }

        &.color8 {
          background-color: #c780e8;
        }
      }
    }
  }
}

.container {
  width: 100vw;
  height: 100vh;

  &.signin {
    background-color: $black;
    color: $white;
    display: flex;
    align-items: center;
    justify-content: center;

    > div {
      text-align: center;

      .title {
        font-size: 4rem;
        font-weight: 800;
        margin-bottom: 1.8rem;
      }

      > div {
        height: 80px;

        button {
          padding: 0 15px;
          background-color: $white;
          border-radius: 3px;

          svg {
            margin-right: 2px;
            vertical-align: middle;
          }

          span {
            display: inline-block;
            color: rgba(0, 0, 0, 0.54);
            font-size: 16px;
            vertical-align: middle;
            text-transform: uppercase;
            font-family: 'Roboto';
            font-weight: 700;
          }
        }
      }
    }
  }
}
.cvc,
.mss,
.dbpq,
.dbpq-generator,
.tts,
.svs,
.tuner,
.bwe,
.admin {
  .main {
    padding: 0 1rem;
  }

  .results-container {
    .result-container {
      border-radius: 4px;
      box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
        0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
    }
  }
  h3.MuiTypography-root {
    color: #3a3a3a;
    font-size: 0.9rem;
    font-weight: 800;
    text-transform: uppercase;
  }

  h6.MuiTypography-root {
    font-size: 0.7rem;
  }
  .results .execute button {
    box-shadow: none;
  }
}

.cvc,
.svs,
.tts,
.bwe,
.mss {
  .contents {
    padding: 0 1rem 2rem;
  }
  #area-main {
    padding-right: 0;
  }
  .main {
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-flow: column;
    overflow-y: overlay;
    * {
      box-sizing: border-box;
    }
    .report-button {
      margin-top: auto;
      margin-bottom: 1rem;
      width: 100%;
    }
  }
}

.cvc {
  .inner {
    min-height: 24px;
  }
  .btn-use-source {
    text-transform: none;
    font-size: 0.75rem;
  }
  .btn-audio {
    text-transform: none;
    font-size: 0.75rem;
    width: 100px;
    justify-content: flex-start;
  }
  .cvc-mode-desc {
    padding: 0.5rem;
    margin: 0;
    border-radius: 0.25rem;
    background-color: #ff7a48;
    color: #000;
  }
  #area-main {
    padding-right: 0;
  }
}

.svs {
  .audio-label {
    font-size: 0.9rem;
    color: rgb(58, 58, 58);
    font-weight: 600;
  }

  .audio-container {
    margin-top: 15px;
  }

  .dropzone {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5px;
    transition: background-color 0.1s ease-in-out;
    border-radius: 5px;
    font-size: 0.9rem;
    margin-top: 15px;
    cursor: pointer;

    > p {
      text-align: center;
      display: flex;
      align-items: center;
      > svg {
        margin-right: 0.2rem;
      }
    }
  }
}

.mss {
  .parameters {
    section {
      padding: 1rem 0;

      .model-group-name {
        font-size: 1.1rem;
        font-weight: 600;
        margin-bottom: 0.5rem;
        color: #2a2a2a;
      }

      .model-types {
        display: flex;
        flex-flow: column;
        margin-bottom: 0.5rem;

        .model-type {
          margin-right: 0.5rem;
          margin-bottom: 0.5rem;
          padding: 0.2rem 0.5rem;
          border-radius: 0.25rem;
          background-color: #f3f4f6;
          color: #555;
          font-size: 0.9rem;
          font-weight: 600;
          cursor: pointer;

          &.selected {
            background-color: #e2e8f0;
          }
        }
      }
    }

    .task-title {
      font-size: 1.2rem;
      font-weight: 600;
      text-transform: none;
      margin-top: 0.4rem;
      padding-bottom: 0.8rem;
    }

    .radio-group-label {
      display: inline-block;
      font-size: 1.1rem;
      font-weight: 600;
      width: 8rem;
      margin-bottom: 0.4rem;
    }
  }
}

.area-grid.bwe {
  #area-main {
    padding-right: 0;
  }

  .contents {
    padding: 0 1rem;

    section {
      margin-bottom: 2rem;

      .inner {
        display: flex;
        flex-direction: column;
        min-height: 1.4rem;

        > div {
          flex: 10;
        }

        .title {
          font-size: 0.9rem;
          text-transform: uppercase;
          color: $gray;
          font-weight: 800;
        }

        .language-label {
          font-size: 0.9rem;
          color: rgb(58, 58, 58);
          font-weight: 600;
        }
      }

      > .field-name {
        font-size: 0.9rem;
        color: $gray;
        font-weight: 600;
      }
    }

    textarea {
      width: 100%;
      font-size: 1rem;
      padding: 0.5rem;
      box-sizing: border-box;
      min-height: 200px;
      resize: vertical;
      margin-top: 0.5rem;
    }

    .note {
      color: #555;
      font-size: 0.8rem;
    }

    .dropzone {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 5px;
      transition: background-color 0.1s ease-in-out;
      border-radius: 5px;
      font-size: 0.9rem;
      margin-top: 0.5rem;
      cursor: pointer;

      > p {
        text-align: center;
        display: flex;
        align-items: center;
        > svg {
          margin-right: 0.2rem;
        }
      }
    }
  }

  input[type='range'] {
    appearance: none;
    -webkit-appearance: none;
    height: 12px;
    background-color: transparent;

    &:focus {
      outline: none;
    }

    &::-webkit-slider-runnable-track {
      width: 100%;
      height: 4px;
      cursor: pointer;
      background-color: $black;
      border-radius: 4px;
    }

    &::-webkit-slider-thumb {
      width: 11px;
      height: 11px;
      border-radius: 11px;
      background-color: $gray;
      cursor: pointer;
      appearance: none;
      -webkit-appearance: none;
      margin-top: -4px;
    }
  }
}

.area-grid.tts {
  #area-main {
    padding-right: 0;
  }

  .contents {
    padding: 0 1rem;

    section {
      margin-bottom: 2rem;

      .inner {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        min-height: 1.4rem;

        > div {
          flex: 10;
        }

        .title {
          font-size: 0.9rem;
          text-transform: uppercase;
          color: $gray;
          font-weight: 800;
        }

        .language-label {
          font-size: 0.9rem;
          color: rgb(58, 58, 58);
          font-weight: 600;
        }
      }

      > .field-name {
        font-size: 0.9rem;
        color: $gray;
        font-weight: 600;
      }
    }

    textarea {
      width: 100%;
      font-size: 1rem;
      padding: 0.5rem;
      box-sizing: border-box;
      min-height: 200px;
      resize: vertical;
      margin-top: 0.5rem;
    }

    .note {
      color: #555;
      font-size: 0.8rem;
    }

    .dropzone {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 5px;
      transition: background-color 0.1s ease-in-out;
      border-radius: 5px;
      font-size: 0.9rem;
      margin-top: 0.5rem;
      cursor: pointer;

      > p {
        text-align: center;
        display: flex;
        align-items: center;
        > svg {
          margin-right: 0.2rem;
        }
      }
    }
  }

  input[type='range'] {
    appearance: none;
    -webkit-appearance: none;
    height: 12px;
    background-color: transparent;

    &:focus {
      outline: none;
    }

    &::-webkit-slider-runnable-track {
      width: 100%;
      height: 4px;
      cursor: pointer;
      background-color: $black;
      border-radius: 4px;
    }

    &::-webkit-slider-thumb {
      width: 11px;
      height: 11px;
      border-radius: 11px;
      background-color: $gray;
      cursor: pointer;
      appearance: none;
      -webkit-appearance: none;
      margin-top: -4px;
    }
  }
}

.dbpq-generator {
  * {
    box-sizing: border-box;
  }
  section {
    margin-bottom: 2rem;
  }
  .output,
  .preview {
    padding: 0;
  }
  .uploader,
  .output {
    h2 {
      width: 100%;
      margin-bottom: 1rem;
      font-size: 1.8rem;
    }
  }
  .actions {
    button {
      line-height: 2rem;
    }
  }

  .video-container {
    position: relative;
    overflow: hidden;
    padding-bottom: 56.25%;
    padding-top: 30px;
    iframe {
      border: none;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .uploader {
    .dropzone {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 5px;
      color: $gray;
      transition: background-color 0.1s ease-in-out;
      background-color: $lightgray;
      border-radius: 5px;
      font-size: 0.9rem;
      margin-top: 0.3rem;
      cursor: pointer;

      &:hover {
        background-color: #f3f4f6;
      }

      > p {
        text-align: center;
        display: flex;
        align-items: center;
      }
    }
  }

  .media-list {
    position: relative;
    display: flex;
    .draggable {
      padding: 0.5rem 1rem;
      display: flex;
      flex-wrap: wrap;
      width: calc(100vw - 6rem);
      background: $lightgray;
      border-radius: 5px;
      &-list {
        margin-bottom: 0.5rem;
        display: flex;
        flex-flow: column;
        width: 100%;
        // &:last-child {
        //   margin-bottom: 0;
        // }
        &:first-child {
          margin-top: 0.5rem;
        }
      }
    }
    .media {
      box-sizing: border-box;
      width: 240px;
      background: #f0f0f0;
      border-radius: 1rem;
      padding: 1rem;

      .name {
        display: block;
        word-break: break-all;
        word-wrap: break-word;
        margin-bottom: 0.5rem;
      }

      input {
        display: block;
      }
    }
  }

  .output {
    code {
      display: block;
      word-break: break-all;
      word-wrap: break-word;
    }
  }
}

// Loader
.lds-facebook {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-facebook div {
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 14px;
  background: $white;
  animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.lds-facebook div:nth-child(1) {
  left: 8px;
  animation-delay: -0.24s;
}
.lds-facebook div:nth-child(2) {
  left: 32px;
  animation-delay: -0.12s;
}
.lds-facebook div:nth-child(3) {
  left: 56px;
  animation-delay: 0;
}
@keyframes lds-facebook {
  0% {
    top: 8px;
    height: 55px;
  }
  50%,
  100% {
    top: 24px;
    height: 32px;
  }
}

.full {
  width: 100vw;
  height: 100vh;
  background-color: $black;
  display: flex;
  align-items: center;
  justify-content: center;

  > div {
    color: $white;
    font-size: 2rem;
    line-height: 1.4;
  }
}

input[type='checkbox'].large {
  appearance: none;
  -webkit-appearance: none;
  background-color: $white;
  margin: 0 0.6rem 0 0;
  color: $black;
  width: 18px;
  height: 18px;
  border: 1px solid $gray;
  border-radius: 6px;
  transform: translateY(-0.15rem);
  display: inline-grid;
  place-content: center;
  cursor: pointer;

  &::before {
    content: '';
    width: 12px;
    height: 12px;
    box-shadow: inset 14px 14px $blue;
    transform: scale(0);
    transition: 100ms transform ease-in-out;
    transform-origin: center center;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  }

  &:checked::before {
    transform: scale(1);
  }
}

.playground-container {
  .area-main {
    background-color: $lightblack;
  }

  .area-status-bar {
    background-color: $black;
  }

  .block-menu {
    display: flex;
    flex-direction: column;

    .st-panel-body {
      padding: 0;
    }

    .block-menu-group {
      display: block;
      background-color: $dimmedgray;
      color: $white;
      padding: 0.2rem 1rem;
      font-size: 0.8rem;
      margin-top: 10px;

      &:first-of-type {
        margin-top: 0;
      }
    }

    .block-menu-items {
      padding: 10px 10px;
    }

    .btn-add-block {
      background-color: $lightblack;

      .st-button {
        width: 100%;
        height: 100%;
        background-color: $gray;
        color: $dimmedgray;
        font-size: 0.8rem;
      }
    }
  }

  .block-menu-item {
    background-color: $black;
    cursor: pointer;
    user-select: none;
    border-radius: 4px;
    box-shadow: 4px 4px 8px 0 $gray;
    margin-bottom: 10px;

    > img {
      width: 100%;
      pointer-events: none;
    }

    > span {
      display: inline-block;
      color: $white;
      font-size: 0.7rem;
      top: 0;
      left: 0;
      background-color: $black;
      padding: 3px 2px 5px;
      margin: 1px 3px;
      line-height: 0.9;
      width: 75px;
      box-sizing: border-box;
      pointer-events: none;
      word-break: break-word;
      white-space: break-spaces;
    }
  }

  .block {
    filter: drop-shadow(2px 2px 4px $white);

    &.active {
      filter: drop-shadow(2px 2px 7px $selectionColor);
    }

    &.highlight {
      filter: drop-shadow(2px 2px 7px $highlightColor);
    }

    .block-bg {
      cursor: move;
    }

    .block-label {
      fill: $white;
      font-size: 0.9rem;
      user-select: none;
      pointer-events: none;
    }

    line {
      pointer-events: none;
    }

    .block-parameters {
      fill: $white;
      font-size: 0.7rem;

      .block-parameter {
        text {
          user-select: none;
          pointer-events: none;
        }

        .port {
          &:hover {
            cursor: pointer;
          }
        }

        &.connected,
        &.active {
          .port {
            fill: $edgeColor;

            &:hover {
              filter: none;
            }
          }
        }

        &.suggest {
          text {
            fill: $edgeColor;
          }

          .port {
            &.inbound {
              filter: drop-shadow(-3px 0 5px $edgeColor);
            }

            &.outbound {
              filter: drop-shadow(3px 0 5px $edgeColor);
            }
          }
        }

        &.highlight {
          text {
            fill: $highlightColor;
          }
        }
      }
    }
  }

  .edge {
    stroke: $edgeColor;
    stroke-width: 2;
    fill: none;
  }

  .properties {
    .properties-label {
      color: $white;
      font-size: 1.2rem;
    }

    .properties-list {
      margin-top: 2rem;

      .property {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 1rem;

        .property-label {
          color: $white;
          font-size: 0.9rem;
          margin-bottom: 0.4rem;
        }

        .property-value {
          flex-grow: 1;
          text-align: right;
          color: $dimmedgray;
          font-size: 0.8rem;

          > img {
            vertical-align: middle;
          }
        }

        .sub-property-container {
          color: $white;
          padding-left: 22px;
          flex-grow: 1;

          .sub-property {
            padding-bottom: 4px;
            border-bottom: 1px dotted $gray;
            margin-bottom: 3px;

            h5 {
              display: list-item;
              list-style-type: square;
              margin-top: 24px;
              margin-bottom: 4px;
              color: $lightgray;
              font-size: 0.8rem;
              font-weight: normal;

              &:first-of-type {
                margin-top: 10px;
              }
            }

            .sources-pane {
              padding: 10px;
              background-color: $black;
              box-sizing: border-box;
              border-radius: 4px;
              font-size: 0.8rem;
            }

            input[type='range'] {
              display: inline-block;
              appearance: none;
              -webkit-appearance: none;
              width: 100%;
              height: 15px;
              background-color: transparent;

              &:focus {
                outline: none;
              }

              &::-webkit-slider-runnable-track {
                width: 100%;
                height: 5px;
                cursor: pointer;
                background-color: $dimmedgray;
              }

              &::-webkit-slider-thumb {
                width: 15px;
                height: 15px;
                border-radius: 15px;
                background-color: $lightgray;
                cursor: pointer;
                appearance: none;
                -webkit-appearance: none;
                margin-top: -6px;
              }
            }

            .dropzone {
              position: relative;
              padding: 10px;
              background-color: $black;
              box-sizing: border-box;
              border-radius: 4px;
              text-align: center;

              input[type='file'] {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                opacity: 0;
              }

              span {
                font-size: 0.8rem;
                color: $dimmedgray;
              }
            }
          }
        }
      }
    }
  }

  .quick-menu {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: $gray;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.2rem 0.5rem;

    .st-button {
      width: 32px;
      height: 32px;
      background-color: $gray;
      padding: 0;

      &:hover {
        background-color: $lightblack;
      }

      img {
        vertical-align: middle;
      }
    }

    .st-separator {
      width: 1px;
      height: 32px;
      background-color: $lightblack;
      margin-left: 0.5rem;
      margin-right: 0.5rem;
    }
  }

  .st-button {
    background-color: $gray;
    padding: 0.2rem 0.5rem;
    border-radius: 4px;
    color: $lightgray;
    font-size: 0.8rem;
  }
}

.flex-break {
  flex-basis: 100%;
  height: 0;
}

button.MuiButton-outlined {
  background: $white;
  text-transform: none;
  &:hover {
    background: $white;
    opacity: 0.8;
  }
}

hr.MuiDivider-root {
  margin: 2rem 0;
}

.parameter {
  label.MuiFormControlLabel-root {
    margin-left: 0;
    display: flex;
    align-items: center;
  }
  .MuiTypography-root {
    font-size: 0.9rem;
    color: #3a3a3a;
    font-weight: 600;
    flex-grow: 1;
    white-space: nowrap;
  }
  .MuiSlider-root {
    margin-top: 1rem;
    .MuiSlider-mark {
      visibility: hidden;
    }
    .MuiSlider-markLabel {
      margin-top: -0.2rem;
      font-size: 0.8rem;
      color: #666;
      &[data-index='0'] {
        margin-left: 0.2rem;
      }
      &[data-index='1'] {
        margin-left: -0.2rem;
      }
    }
  }
}

// Reduce the spacing between the icon and the text in the button.
.MuiButton-startIcon.MuiButton-iconSizeSmall {
  margin-right: 4px;
}
